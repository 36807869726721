import { fetchApi, fetchFunction } from "../fetchFunction";

export const getEventClientDetail = async ({ clientId }) => {
    const options = {
        method: 'GET'
    }
    return await fetchApi(`/event/client/${clientId}`, options)
}

export const getEventDetail = async ({ id }) => {
    const options = {
        method: 'GET'
    }
    return await fetchApi(`/event/detail/${id}`, options)
}

export const postEventCoupon = async ({ data }) => {
    const options = {
        method: 'POST',
        body: JSON.stringify(data)
    }
    return await fetchApi(`/event/coupon`, options)
}

export const getEventCouponByDocument = async ({ document, eventId }) => {
    const options = {
        method: 'GET'
    }
    return await fetchApi(`/event/document?eventId=${eventId}&document=${document}`, options)
}

export const getEventCouponStatus = async ({ id }) => {
    const options = {
        method: 'GET'
    }
    return await fetchApi(`/event/coupon/status/${id}`, options)
}

export const postGenerateCouponQRCode = async ({ data }) => {
    const options = {
        method: 'POST',
        body: JSON.stringify(data)
    }
    return await fetchFunction(`/event/coupon`, options)
}
