
import React from 'react'
import { documentMask, goToWindow, loadClientSession, queryString, reloadWithAlert, searchIpInfo, takeIfIsNotNullOrEmpty, toCurrency } from '../../shared/utils'
import { ImageContainer, RegisterContainer } from '../../shared/layout/ticket-container'
import { LoadingContainer } from '../../shared/layout/system-container'
import { getFormMonthlyChargeById, getFormMonthlyChargesByMonthlyId, postContractSignByMonthlyId } from '../../config/api/form/formMonthlyCharge'
import moment from 'moment'
import { PaymentFlowView } from '../payment'
import { MainContainer } from '../../shared/layout/system-container'

class MonthlyChargeItemView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isPaymentEnabled: props.isPaymentEnabled,
            charge: props.charge,
            showPayButton: props.showPayButton,
            isMoreShowing: false,
            type: "charge",
            status: props.charge.status,
            pix: props.charge.settings.pay.pix,
            credit: props.charge.settings.pay.credit,
            note: props.charge.settings.note.enabled,
            pixQRCode: props.charge.pixQRCode,
            park: props.park,
            monthly: props.monthly,
        }
    }

    render() {
        return (
            <>
                {
                    (this.state.charge.status === "pending" || this.state.charge.status === "late") &&
                    <div className="col-lg-12">
                        <div className={this.state.charge.status === "pending" ? "alert alert-warning" : "alert alert-danger"}>
                            {
                                this.state.charge.calculate.discountOrTax === 0 &&
                                <>
                                    {this.state.charge.status === "pending" && <span className="badge text-bg-warning text-white">Aguardando pagamento</span>}
                                    {this.state.charge.status === "late" && <span className="badge text-bg-danger text-white">Cobrança atrasada</span>}
                                    <br />
                                    <h1><strong className={this.state.charge.status === "pending" ? "text-warning" : "text-danger"}>{toCurrency(this.state.charge.calculate.total)}</strong></h1>
                                </>
                            }
                            {
                                this.state.charge.calculate.discountOrTax > 0 &&
                                <>
                                    <span className="badge text-bg-secondary text-white"> {this.state.charge.calculate.porcentagem}% de juros ou taxa. </span>
                                    <br />
                                    <small className={"text-secondary"}>De <small style={{ textDecoration: "line-through" }}>{toCurrency(this.state.charge.calculate.originalPrice + (this.state.charge.calculate.totalSpun || 0))}</small> por </small>
                                    <br />
                                    <h1>
                                        <strong className={this.state.charge.status === "pending" ? "text-warning" : "text-danger"}>{toCurrency(this.state.charge.calculate.total)}</strong>
                                    </h1>
                                </>
                            }
                            {
                                this.state.charge.calculate.discountOrTax < 0 &&
                                <>
                                    <span className="badge text-bg-success text-white">🔥 {this.state.charge.calculate.porcentagem}% Desconto pagando hoje </span>
                                    <br />
                                    <small className={"text-secondary"}>De <small style={{ textDecoration: "line-through" }}>{toCurrency(this.state.charge.calculate.originalPrice + (this.state.charge.calculate.totalSpun || 0))}</small> por </small>
                                    <br />
                                    <h1>
                                        <strong className={this.state.charge.status === "pending" ? "text-warning" : "text-danger"}>{toCurrency(this.state.charge.calculate.total)}</strong>
                                    </h1>
                                </>
                            }
                            {
                                (this.state.charge.calculate.totalSpun ?? 0) > 0 && <>
                                    <h6 onClick={(e) => this.setState({ isMoreShowing: !this.state.isMoreShowing })}>
                                        <strong>{toCurrency(this.state.charge.calculate.total - this.state.charge.calculate.totalSpun)} da cobrança</strong><br />
                                        <strong role="button" className="text-success">+{toCurrency(this.state.charge.calculate.totalSpun)} em consumo</strong>
                                        <i role="button" className={`mx-2 fas fa-chevron-circle-${this.state.isMoreShowing ? "down" : "right"} text-success`} />
                                    </h6>
                                    {
                                        this.state.isMoreShowing &&
                                        <>
                                            {
                                                this.state.charge.spuns.map((spun, index) => {
                                                    return <div className="col-lg-12">
                                                        <table className="table table-borderless">
                                                            <thead>
                                                                <tr>
                                                                    <td></td>
                                                                    <td><strong>Ticket</strong></td>
                                                                    <td><strong>Placa</strong></td>
                                                                    <td align="right"><strong>Data</strong></td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr key={`spun_${index}`}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{spun.ticket}</td>
                                                                    <td>{spun.plate}</td>
                                                                    <td align="right">{moment(spun.createdAt._seconds * 1000).format("DD/MM/YYYY")}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <table className="table table-borderless">
                                                            <thead>
                                                                <tr>
                                                                    <td><strong>Produto</strong></td>
                                                                    <td align="center"><strong>Quantidade</strong></td>
                                                                    <td align="right"><strong>Total</strong></td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    spun.products.map((product, productIndex) => {
                                                                        return <tr key={`products_${index}_${productIndex}`}>
                                                                            <td>{product.name}</td>
                                                                            <td align="center">{product.quantity}</td>
                                                                            <td align="right">{toCurrency(product.price)}</td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                })
                                            }
                                        </>
                                    }
                                </>
                            }
                            <strong>Vencimento em {moment(this.state.charge.changeDate._seconds * 1000).format("DD/MM/YYYY")}</strong>
                            {
                                this.state.charge.settings.pay.enabled && <>
                                    {
                                        this.state.showPayButton ?
                                            <div className="col-12 col-lg-3">
                                                <button className={this.state.charge.status === "pending" ? "btn btn-warning w-100 text-white" : "btn btn-danger w-100 text-white"} onClick={(e) => goToWindow(`/mensalista/cobranca/?id=${queryString("id")}&m=${this.state.charge.id}`)}>
                                                    <i className="fas fa-money-bill-wave-alt mx-2" />
                                                    Pagar agora
                                                </button>
                                            </div>
                                            : <PaymentFlowView {...this.state} document={this.state.monthly?.document} id={this.state.charge.id} />
                                    }
                                </>
                            }
                        </div>
                    </div>
                }
                {
                    this.state.charge.status === "payed" &&
                    <div className="col-lg-12 mt-3">
                        <div className="alert alert-success">
                            <span className="badge text-bg-success text-white">Cobrança paga</span> <br />
                            <strong>Valor da cobrança</strong>
                            <h4><strong className='text-success'>{toCurrency(this.state.charge.payment.total)}</strong></h4>
                            <strong>Vencimento em {moment(this.state.charge.changeDate._seconds * 1000).format("DD/MM/YYYY")}</strong>
                            {
                                this.state.charge.payment &&
                                <>
                                    <br /> <strong>Pago em {moment(this.state.charge.payment.date._seconds * 1000).format("DD/MM/YYYY")}</strong>
                                </>
                            }
                            <div className="row">
                                <div className="col-md-12 col-lg-3">
                                    {
                                        this.state.charge.note && <>
                                            {
                                                this.state.charge.note?.currentStatus === "NOTE_PROCESSING" &&
                                                <div className="alert alert-warning mt-2">
                                                    <h6>Estamos gerando a sua nota fiscal, isso pode levar até 2 dias.</h6>
                                                </div>
                                            }
                                            {
                                                this.state.charge.note?.currentStatus === "NOTE_ERROR" &&
                                                <div className="alert alert-danger mt-2">
                                                    <h6>Não foi possível gerar a sua nota fiscal, entre em contato com o estabelecimento.</h6>
                                                </div>
                                            }
                                            {
                                                this.state.charge.note?.currentStatus === "NOTE_CANCELED" &&
                                                <a href={this.state.charge.note.pdf} target="_blank" rel="noreferrer" className="btn btn-danger w-100 text-white my-3">
                                                    <i className="fa-solid fa-file-pdf mx-2" />
                                                    Visualizar nota cancelada
                                                </a>
                                            }
                                            {
                                                this.state.charge.note?.currentStatus === "NOTE_GENERATED" &&
                                                <a href={this.state.charge.note.pdf} target="_blank" rel="noreferrer" className="btn btn-success w-100 text-white mb-3">
                                                    <i className="fa-solid fa-file-pdf mx-2" />
                                                    Visualizar nota
                                                </a>
                                            }
                                        </>
                                    }
                                    {/* {
                                        this.state.charge.settings.receipt &&
                                        <button onClick={(e) => { toastWarning("Não disponível") }} className="btn btn-success w-100 text-white">
                                            <i className="fa-solid fa-file-pdf mx-2" />
                                            Baixar recibo
                                        </button>
                                    } */}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>)
    }
}

class MonthlyItemView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ...props.items,
        }
    }

    render() {
        return (
            <>
                {
                    this.state.park &&
                    <div className="col-lg-12">
                        <div className="alert alert-secondary">
                            <span className="badge text-bg-secondary">Estabelecimento</span> <br />
                            <h4>{this.state.park.name}</h4>
                            {documentMask(this.state.park.document)} - {this.state.park.phone} <br />
                            {this.state.park.address.street}, {this.state.park.address.number} - {this.state.park.address.zipcode} <br />
                            {this.state.park.times}
                        </div>
                    </div>
                }
                {
                    this.state.plan &&
                    <div className="col-lg-12">
                        <h4>Plano</h4>
                        <strong>{this.state.plan.name}</strong> <br />
                        <span>Das {this.state.plan.start} às {this.state.plan.end}</span> <br />
                        {this.state.monthly.slots === 1 ? <span>1 Vaga</span> : <span>{this.state.monthly.slots} Vagas</span>}
                    </div>
                }
                {
                    this.state.monthly &&
                    <div className="col-lg-12">
                        <h4>Mensalista</h4>
                        <strong>
                            {this.state.monthly.name}
                        </strong> <br />
                        <span>{this.state.monthly.cellphone} - {this.state.monthly.address.city}</span>
                    </div>
                }
                {
                    (this.state.vehicles?.length ?? 0) > 0 &&
                    <div className="col-lg-12 mt-3">
                        <h4>Veículos</h4>
                        <div className="col-lg-12 mt-3">
                            <table className="table table-borderless">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Placa</th>
                                        <th scope="col">Modelo</th>
                                        <th scope="col">Cor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.vehicles.map((value, index) => {
                                            return <tr key={`vehicles_${index}`}>
                                                <th>{index + 1}</th>
                                                <td>{value.plate}</td>
                                                <td>{takeIfIsNotNullOrEmpty(value.model) ?? "-"}</td>
                                                <td>{takeIfIsNotNullOrEmpty(value.color) ?? "-"}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </>)
    }
}

class MonthlyChargesView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            state: "loading"
        }
    }

    componentDidMount = async () => {
        const client = await loadClientSession()
        if (client) {
            this.setState({ data: client })
            this.setState({ state: "loading" })
            const m = queryString("m")
            const data = await getFormMonthlyChargesByMonthlyId({ id: m })
            if (data.success) {
                this.setState({ ...data.data })
                this.setState({ state: "fill" })
            } else {
                this.setState({ errorMessage: data.message })
                this.setState({ state: "not-found" })
            }
        } else {
            this.setState({ state: "not-found" })
        }
    }

    render() {
        return (
            <div className="container-md top-20">
                {
                    this.state.data?.tema?.logo &&
                    <ImageContainer url={this.state.data?.tema?.logo} />
                }
                <RegisterContainer>
                    {this.state.state === "not-found" &&
                        <div className="alert alert-danger text-center mt-4">
                            <span className="badge text-bg-danger">Oops!</span> <p />
                            <h4>Cobrança não encontrada</h4>
                            <span>Entre em contato com o estabelecimento.</span>
                        </div>
                    }
                    {this.state.state === "loading" &&
                        <div className="text-center">
                            <LoadingContainer />
                        </div>
                    }
                    {
                        this.state.state === "fill" &&
                        <div className="row mt-3">
                            <div className="text-center">
                                <h3>Todas as cobranças</h3>
                                <span>Dados detalhados de todas as cobranças</span>
                            </div>
                            <div className="mt-2">
                                <MonthlyItemView items={this.state} />
                            </div>
                            <div className="mt-3">
                                {
                                    this.state.charges.map(e => {
                                        return <MonthlyChargeItemView key={e.id} charge={e} showPayButton={true} />
                                    })
                                }
                            </div>
                        </div>
                    }
                </RegisterContainer>
            </div>
        )
    }
}

class MonthlyChargeView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            state: "loading",
            aceitarContratoDeUso: false,
            park: null
        }
    }

    componentDidMount = async () => {
        const client = await loadClientSession()
        if (client) {
            this.setState({ data: client })
            const m = queryString("m")
            if (m) {
                this.setState({ state: "loading" })
                const data = await getFormMonthlyChargeById({ id: m })
                if (data.success) {
                    this.setState({ ...data.data })
                    if (data.data.contract) {
                        this.setState({ state: "contract" })
                    } else {
                        this.setState({ state: "fill" })
                    }
                } else {
                    this.setState({ errorMessage: data.message })
                    this.setState({ state: "not-found" })
                }
            } else {
                this.setState({ state: "not-found" })
            }
        }
    }

    signContract = async () => {
        if (this.state.aceitarContratoDeUso) {
            var ip = await searchIpInfo()
            const data = {
                monthlyId: this.state.monthly.id,
                contract: {
                    isAccept: this.state.aceitarContratoDeUso,
                    ip: ip
                }
            }
            this.setState({ state: "loading" })
            const result = await postContractSignByMonthlyId({ data: data })
            if (result.success) {
                this.setState({ state: "fill" })
            } else {
                reloadWithAlert(result.message)
            }
        } else {
            alert("Você precisa aceitar os termos de uso do estabelecimento.")
        }
    }

    render() {
        return (
            <MainContainer>
                <div className="container-md top-20">
                    {
                        this.state.data?.tema?.logo &&
                        <ImageContainer url={this.state.data?.tema?.logo} />
                    }
                    <RegisterContainer>
                        {
                            this.state.state === "not-found" &&
                            <div className="alert alert-danger text-center mt-4">
                                <span className="badge text-bg-danger">Oops!</span> <p />
                                <h4>Cobrança não encontrada</h4>
                                <span>Entre em contato com o estabelecimento.</span>
                            </div>
                        }
                        {
                            this.state.state === "loading" &&
                            <div className="text-center">
                                <LoadingContainer />
                            </div>
                        }
                        {
                            this.state.state === "fill" &&
                            <div className="row mt-3">
                                <div className="text-center">
                                    <h3>Detalhes da cobrança</h3>
                                    <span>Dados detalhados da cobrança</span>
                                </div>
                                <div className="mt-1">
                                    <MonthlyChargeItemView charge={this.state.charge}
                                        monthly={this.state.monthly}
                                        park={this.state.park}
                                        isPaymentEnabled={this.state.flags?.isPaymentEnabled} />
                                </div>
                                <div className="col-md-12 col-lg-12">
                                    <a href={`/mensalista/cobrancas/?id=${queryString("id")}&m=${this.state.monthly.id}`} className="btn btn-secondary w-100 text-white">
                                        <i className="fa-solid fa-list mx-2" />
                                        Visualizar todas
                                    </a>
                                </div>
                                <div className="mt-3">
                                    <MonthlyItemView items={this.state} />
                                </div>
                            </div>
                        }
                        {
                            this.state.state === "contract" &&
                            <>
                                <div className="text-center">
                                    <h3>Contrato</h3>
                                    <span>Tivemos atualizações nos termos de uso e/ou política de privacidade do estabelecimento.</span>
                                </div>
                                <div className="col-lg-12 mt-3">
                                    <textarea className="form-control" rows="15" disabled>
                                        {this.state.contract?.html}
                                    </textarea>
                                </div>
                                <div className="col-lg-12 mt-3">
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" id="aceitarContratoDeUso" checked={this.state.aceitarContratoDeUso} onChange={(e) => this.setState({ aceitarContratoDeUso: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="aceitarContratoDeUso">
                                            Li e concordo com os termos de uso.
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-12 mt-3">
                                    <div className="btn-group">
                                        <button className="btn btn-success" onClick={(e) => this.signContract()}>
                                            <i className="fas fa-shield-alt mx-2" />
                                            Assinar contrato
                                        </button>
                                    </div>
                                </div>
                            </>
                        }
                    </RegisterContainer>
                </div>
            </MainContainer>
        )
    }
}

export { MonthlyChargeView, MonthlyChargesView }