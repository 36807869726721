
import React from 'react'
import { documentMask, generateUUID, goToWindow, goToWindowWithAlert, isNullOrEmpty, isValidDocument, isValidEmail, isValidatePlate, loadClientSession, queryString, searchCEP, searchIpInfo, toCurrency, toastWarning } from '../../shared/utils'
import { ImageContainer, RegisterContainer } from '../../shared/layout/ticket-container'
import { getAllVehiclesColors, getAllVehiclesModels } from '../../config/api/dataApi'
import NumberFormat from 'react-number-format'
import { LoadingContainer } from '../../shared/layout/system-container'
import { initializeForm, loginForm, } from '../../config/api/form/formRegister'
import { postFormMonthly } from '../../config/api/form/formMonthly'
import { postFormAccredited } from '../../config/api/form/formAccredited'
import { MainContainer } from '../../shared/layout/system-container'

class RegisterView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            state: "loading",
            park: null,
            name: "",
            cellphone: queryString("p") ?? "",
            email: "",
            document: "",
            documentType: "CPF",
            dueDate: 5,
            charges: 24,
            slots: 1,
            plan: null,
            flags: null,
            planId: "",
            parkId: "",
            street: "",
            number: "",
            complement: "",
            district: "",
            city: "",
            ibge: "",
            aceitarContratoDeUso: false,
            parks: [],
            dueDays: [],
            vehicles: [],
            allCharges: [],
            plans: [],
            vehiclesModels: [],
            vehiclesColors: [],
            forms: [],
            currentForm: "monthly",
            plate: "",
            model: "",
            color: ""
        }
    }

    componentDidMount = async () => {
        const client = await loadClientSession()
        if (client) {
            this.setState({ data: client })
            this.setState({ state: "loading" })
            this.setState({ canChange: queryString("c") === "true" })
            const data = await initializeForm({ id: client.id })
            if (data.success) {
                this.setState({ state: "fill" })
                this.setState({ ...data.data }, () => {
                    this.changePark(queryString("parkId"))
                })
                this.loadDataFromVehicles()
            } else {
                this.setState({ errorMessage: data.message })
                this.setState({ state: "not-found" })
            }
        } else {
            this.setState({ state: "not-found" })
        }
    }

    loadDataFromVehicles = async () => {
        const resultVehiclesModels = await getAllVehiclesModels()
        if (!resultVehiclesModels.success) {
            goToWindowWithAlert("Não conseguimos buscar todas as informações, entre em contato com o estabelecimento.", "/")
        }
        const resultVehiclesColors = await getAllVehiclesColors()
        if (!resultVehiclesColors.success) {
            goToWindowWithAlert("Não conseguimos buscar todas as informações, entre em contato com o estabelecimento.", "/")
        }
        this.setState({ vehiclesModels: resultVehiclesModels.data })
        this.setState({ vehiclesColors: resultVehiclesColors.data })
    }

    fetchCEP = async (value) => {
        const result = await searchCEP(value)
        this.setState({ zipcode: value })
        if (result) {
            if (result.erro) {
                this.setState({ street: "" })
                this.setState({ complement: "" })
                toastWarning("CEP não encontrado")
            } else {
                this.setState({ street: result.logradouro })
                this.setState({ complement: result.complemento })
                this.setState({ district: result.bairro })
                this.setState({ city: result.localidade })
                this.setState({ uf: result.estado })
                this.setState({ ibge: result.ibge })
            }
        }
    }

    changePark = async (value) => {
        if (value) {
            this.setState({ parkId: value })
            const park = this.state.parks.filter(e => e.id === value)[0]
            if (park) {
                this.setState({ forms: park.forms })
                if (park.forms.length === 1) {
                    const currentForm = park.forms[0]
                    this.setState({ currentForm: currentForm })
                }
                this.setState({ park: park }, () => {
                    const slots = queryString("slots")
                    if (slots) {
                        this.setState({ slots: parseInt(slots) }, () => {
                            this.setState({ plans: park.plans }, () => {
                                this.selectPlan(queryString("planId"))
                            })
                        })
                    } else {
                        this.setState({ plans: park.plans }, () => {
                            this.selectPlan(queryString("planId"))
                        })
                    }
                    this.setState({ flags: park.flags })
                })
            }
        }
    }

    selectPlan = (value) => {
        if (value) {
            const plan = this.state.plans.filter(e => e.id === value)[0]
            if (plan) {
                this.setState({ planId: plan.id })
                this.setState({ plan: plan })
                if (plan.dueDays.indexOf("Todos os dias") !== -1) {
                    var allDueDays = []
                    for (var i = 1; i < 31; i++) {
                        allDueDays.push(i.toString())
                    }
                    this.setState({ dueDate: allDueDays[0] })
                    this.setState({ dueDays: allDueDays })
                } else {
                    this.setState({ dueDate: plan.dueDays[0] })
                    this.setState({ dueDays: plan.dueDays })
                }
                var allCharges = []
                for (var x = 1; x < (plan.maximumCharges + 1); x++) {
                    allCharges.push(x.toString())
                }
                this.setState({ allCharges: allCharges })
            } else {
                toastWarning("Nenhum plano online disponível, entre em contato com o estabelecimento.")
            }
        }
    }

    addVehicle = () => {
        const plate = this.state.plate
        var vehicles = this.state.vehicles
        if (vehicles.map(e => e.plate).includes(plate)) {
            toastWarning("Esta placa já esta adicionada.")
            return
        }
        if (!isValidatePlate(plate)) {
            toastWarning("Informe uma placa válida.")
            return
        }
        vehicles.push({
            id: generateUUID(),
            plate: plate,
            model: this.state.model ?? "Outros",
            color: this.state.color ?? "Outros"
        })
        this.setState({ vehicles: vehicles })
        this.setState({ plate: "" })
        this.setState({ model: "" })
        this.setState({ color: "" })
        this.setState({ vehiclesRandom: Math.random() })
    }

    removeVehicle = (item) => {
        const confirm = window.confirm("Deseja mesmo remover este veículo?")
        if (confirm) {
            var vehicles = this.state.vehicles.filter(e => e.id !== item.id)
            this.setState({ vehicles: vehicles })
        }
    }

    saveAccredited = async () => {
        if (!this.validateFields()) {
            return
        }
        this.setState({ state: "loading" })
        const data = await this.retriveData()
        const result = await postFormAccredited({ data: data })
        if (result.success) {
            goToWindow(`/credenciado/detalhes/?id=${queryString("id")}&c=${result.data.accredited.id}`)
        } else {
            toastWarning(result.message)
            this.setState({ state: "fill" })
        }
    }

    retriveData = async () => {
        var ip = await searchIpInfo()
        return {
            park: {
                id: this.state.park?.id
            },
            client: {
                name: this.state.name,
                cellphone: this.state.cellphone,
                email: this.state.email,
                documentType: this.state.documentType,
                document: documentMask(this.state.document)
            },
            address: {
                zipcode: this.state.zipcode,
                address: this.state.street,
                number: this.state.number,
                complement: this.state.complement,
                city: this.state.city,
                state: this.state.uf,
                district: this.state.district,
                ibge: this.state.ibge
            },
            plan: {
                id: this.state.planId,
                slots: this.state.slots,
                dueDate: this.state.dueDate,
                charges: this.state.charges,
                coupon: this.state.coupon
            },
            contract: {
                isAccept: this.state.aceitarContratoDeUso,
                html: this.state.flags?.contract?.html,
                ip: ip
            },
            vehicles: this.state.vehicles
        }
    }

    saveMonthly = async () => {
        if (!this.validateFields()) {
            return
        }
        this.setState({ state: "loading" })
        const data = await this.retriveData()
        const result = await postFormMonthly({ data: data })
        if (result.success) {
            goToWindow(`/mensalista/cobranca/?id=${result.data.charge.clienteId}&m=${result.data.charge.id}`)
        } else {
            toastWarning(result.message)
            this.setState({ state: "fill" })
        }
    }

    showContract = async () => {
        if (!this.validateFields()) {
            return
        }
        this.setState({ state: "contract" })
    }

    validateFields = () => {
        if (this.state.name === "") {
            toastWarning("Informe o nome do cliente.")
            return false
        }
        if (this.state.cellphone === "") {
            toastWarning("Informe o celular do cliente.")
            return false
        }
        if (this.state.email !== "" && !isValidEmail(this.state.email)) {
            toastWarning("Informe um e-mail válido.")
            return false
        }
        if (this.state.document === "") {
            toastWarning("Informe o documento do cliente.")
            return false
        }
        if ((this.state.documentType === "CPF" || this.state.documentType === "CNPJ") && !isValidDocument(this.state.document)) {
            toastWarning("Informe um documento válido.")
            return false
        }
        if (this.state.zipcode === "") {
            toastWarning("Informe o documento CEP do cliente.")
            return false
        }
        if (this.state.vehicles.length === 0) {
            toastWarning("Você precisa cadastrar ao menos um veículo.")
            return false
        }
        if (this.state.currentForm === "monthly" && this.state.planId === "") {
            toastWarning("Você precisa escolher o plano de uso.")
            return false
        }
        if (this.state.state === "contract" && this.state.flags?.contract?.isEnabled && !this.state.aceitarContratoDeUso) {
            toastWarning("Você precisa aceitar os termos de uso do estabelecimento.")
            return false
        }
        return true
    }

    render() {
        return (
            <>
                <div className="container-md top-20">
                    {
                        this.state.data?.tema?.logo &&
                        <ImageContainer url={this.state.data?.tema?.logo} />
                    }
                    <RegisterContainer>
                        {
                            this.state.state === "not-found" &&
                            <div className="alert alert-danger text-center mt-4">
                                <span className="badge text-bg-danger">Oops!</span> <p />
                                <h4>Estabelecimento não encontrado</h4>
                                <span>Entre em contato com o estabelecimento e solicite a URL correta do formulário.</span>
                            </div>
                        }
                        {
                            this.state.state === "loading" &&
                            <div className="text-center">
                                <LoadingContainer />
                            </div>
                        }
                        {
                            this.state.state === "fill" &&
                            <div className="card-body">
                                <div className="row mt-3">
                                    <div className="text-center">
                                        <h3>Formulário de cadastro</h3>
                                        <span>Formulário para cadastro de mensalistas ou credenciado.</span>
                                    </div>
                                    <div className="col-lg-12">
                                        <select className='form-select' value={this.state.parkId} onChange={(e) => this.changePark(e.target.value)}>
                                            <option value="">Escolha o estabelecimento</option>
                                            {
                                                this.state.parks.map(e => {
                                                    return <option key={e.id} value={e.id}>{e.name}</option>
                                                })
                                            }
                                        </select>
                                        {
                                            this.state.park &&
                                            <div className="col-lg-12 mt-3">
                                                <div className="alert alert-secondary">
                                                    <span className="badge text-bg-secondary">Estabelecimento</span> <br />
                                                    <h4>{this.state.park.name}</h4>
                                                    {documentMask(this.state.park.document)} - {this.state.park.phone} <br />
                                                    {this.state.park.address.street}, {this.state.park.address.number} - {this.state.park.address.zipcode} <br />
                                                    {this.state.park.times}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {
                                    !this.state.park &&
                                    <div className="text-center mt-1">
                                        <h5>OU</h5>
                                        <div className="col-md-12 col-lg-12">
                                            <a href={`/formulario/entrar/?id=${queryString("id")}`} className="btn btn-success w-100">
                                                <i className="fas fa-lock mx-2" />
                                                Já sou cadastrado
                                            </a>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.park && <>
                                        {
                                            this.state.forms.length === 0 ? <div>
                                                <div className="alert alert-info text-center">
                                                    <span className="badge text-bg-info text-white">Oops!</span> <p />
                                                    <h4>Este estabelecimento não permite cadastro por aqui.</h4>
                                                    <span>Entre em contato com o estabelecimento e solicite esta funcionalidade.</span>
                                                </div>
                                            </div> :
                                                <>
                                                    <div className="row">
                                                        {
                                                            this.state.forms.length > 1 &&
                                                            <div className="col-md-6 col-lg-3">
                                                                <strong>Tipo de cadastro</strong>
                                                                <select className='form-select' value={this.state.currentForm} onChange={e => this.setState({ currentForm: e.target.value })} >
                                                                    {
                                                                        this.state.forms.filter(e => e === "monthly").length > 0 && <option value='monthly'>Mensalista</option>
                                                                    }
                                                                    {
                                                                        this.state.forms.filter(e => e === "accredited").length > 0 && <option value='accredited'>Credenciado</option>
                                                                    }
                                                                </select>
                                                            </div>
                                                        }
                                                        <h4 className="text-center mt-2">Dados do cliente</h4>
                                                        <div className="col-lg-5">
                                                            <strong>Nome completo ou razão social</strong>
                                                            <input type='text' className='form-control' value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <strong>Celular</strong>
                                                            <NumberFormat className="form-control" mask='_' format={'(##)#####-####'} value={this.state.cellphone} onChange={(e) => this.setState({ cellphone: e.target.value })} />
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <strong>E-mail</strong>
                                                            <input type='email' className='form-control' value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                                        </div>
                                                        <div className="col-md-6 col-lg-3">
                                                            <strong>Tipo de documento</strong>
                                                            <select className='form-select' value={this.state.documentType} onChange={e => this.setState({ documentType: e.target.value })} >
                                                                <option value='CPF'>CPF</option>
                                                                <option value='CNPJ'>CNPJ</option>
                                                                <option value='OTHERS'>Estrangeiro</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 col-lg-3">
                                                            <strong>Número do documento</strong>
                                                            {this.state.documentType === 'CPF' && <NumberFormat className="form-control" mask='_' format={'###.###.###-##'} value={this.state.document} onChange={(e) => this.setState({ document: e.target.value })} />}
                                                            {this.state.documentType === 'CNPJ' && <NumberFormat className="form-control" mask='_' format={'##.###.###/####-##'} value={this.state.document} onChange={(e) => this.setState({ document: e.target.value })} />}
                                                            {this.state.documentType === 'OTHERS' && <input type="text" className="form-control" value={this.state.document} onChange={(e) => this.setState({ document: e.target.value })} />}
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <strong>CEP</strong>
                                                            <NumberFormat autoComplete='off' className="form-control" value={this.state.zipcode} onChange={(e) => this.fetchCEP(e.target.value)} format="#####-###" />
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <strong>Logradouro</strong>
                                                            <input type="text" onChange={(e) => this.setState({ street: e.target.value })} value={this.state.street} className="form-control" required />
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <strong>Número do endereço</strong>
                                                            <input type="text" onChange={(e) => this.setState({ number: e.target.value })} value={this.state.number} className="form-control" required />
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <strong>Complemento</strong>
                                                            <input type="text" onChange={(e) => this.setState({ complement: e.target.value })} value={this.state.complement} className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <h4 className="text-center">Dados dos veículos</h4>
                                                        <div className="col-md-4 col-lg-4">
                                                            <strong>Placa do veículo</strong>
                                                            <input type='text' className='form-control' value={this.state.plate} onChange={(e) => this.setState({ plate: e.target.value.toUpperCase() })} />
                                                        </div>
                                                        <div className="col-md-4 col-lg-3">
                                                            <strong>Modelo</strong>
                                                            <input className="form-control" list="datalistModelsOptions" placeholder="Toque para buscar..." value={this.state.model} onChange={(e) => this.setState({ model: e.target.value })} />
                                                            <datalist id="datalistModelsOptions">
                                                                {
                                                                    this.state.vehiclesModels.map(e => {
                                                                        return <option key={e.value} value={e.value} />
                                                                    })
                                                                }
                                                            </datalist>
                                                        </div>
                                                        <div className="col-md-4 col-lg-3">
                                                            <strong>Cor</strong>
                                                            <select className='form-select' value={this.state.color} onChange={(e) => this.setState({ color: e.target.value })}  >
                                                                <option value="">Selecionar</option>
                                                                {
                                                                    this.state.vehiclesColors.map(e => {
                                                                        return <option key={e.value} value={e.value}>{e.value}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="col-md-12 col-lg-2 mt-4">
                                                            <button className="btn btn-success w-100" onClick={this.addVehicle}>
                                                                <i className="fas fa-plus-circle mx-2" />
                                                                Adicionar
                                                            </button>
                                                        </div>
                                                        {
                                                            this.state.vehicles.length > 0 &&
                                                            <div className="col-lg-12 mt-3">
                                                                <table className="table table-borderless">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col"></th>
                                                                            <th scope="col">Placa</th>
                                                                            <th scope="col">Modelo</th>
                                                                            <th scope="col">Cor</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            this.state.vehicles.map((value, index) => {
                                                                                return <tr key={`vehicles_${index}`}>
                                                                                    <th>{index + 1}</th>
                                                                                    <td>{value.plate}</td>
                                                                                    <td>{value.model}</td>
                                                                                    <td>{value.color}</td>
                                                                                    <td width={10}>
                                                                                        <button className="btn btn-danger w-100" onClick={(e) => { this.removeVehicle(value) }}>
                                                                                            <i className="fas fa-minus-circle mx-2" />
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        }
                                                    </div>
                                                    {
                                                        this.state.currentForm === "accredited" &&
                                                        <div className="row mt-3">
                                                            <div className="col-lg-12">
                                                                <div className="alert alert-warning">
                                                                    <span className="badge text-bg-warning text-white">Atenção</span> <br />
                                                                    Após o cadastro os dados serão analisados e quando forem aprovados ou negados você será notificado via <strong>WhatsApp</strong> no número cadastrado.
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-lg-3 mt-2">
                                                                <button className="btn btn-success w-100" onClick={this.saveAccredited}>
                                                                    <i className="fas fa-lock mx-2" />
                                                                    Confirmar cadastro
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.currentForm === "monthly" &&
                                                        <div className="row mt-3">
                                                            <h4 className="text-center">Sobre o plano</h4>
                                                            <div className="col-lg-3">
                                                                <strong>Escolha o plano</strong>
                                                                <select className='form-select' value={this.state.planId} onChange={(e) => this.selectPlan(e.target.value)} >
                                                                    <option value="">Selecionar</option>
                                                                    {
                                                                        this.state.plans.map(e => {
                                                                            return <option key={e.id} value={e.id}>{e.type?.toUpperCase()} - {e.name}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                            {
                                                                this.state.plan && <>
                                                                    <div className="col-lg-3">
                                                                        <strong>Quantidade de vagas</strong>
                                                                        <select className='form-select' value={this.state.slots} onChange={e => this.setState({ slots: e.target.value })}>
                                                                            <option value="1">1 vaga</option>
                                                                            <option value="2">2 vagas</option>
                                                                            <option value="3">3 vagas</option>
                                                                            <option value="4">4 vagas</option>
                                                                            <option value="5">5 vagas</option>
                                                                            <option value="6">6 vagas</option>
                                                                            <option value="7">7 vagas</option>
                                                                            <option value="8">8 vagas</option>
                                                                            <option value="9">9 vagas</option>
                                                                            <option value="10">10 vagas</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-lg-3">
                                                                        <strong>Dia do vencimento</strong>
                                                                        <select className='form-select' value={this.state.dueDate} onChange={e => this.setState({ dueDate: e.target.value })}>
                                                                            {
                                                                                this.state.dueDays.map(e => {
                                                                                    return <option key={e} value={e}>Todo dia {e}</option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-lg-3">
                                                                        <strong>Vigência do contrato</strong>
                                                                        <select className='form-select' value={this.state.charges} onChange={e => this.setState({ charges: e.target.value })}>
                                                                            {
                                                                                this.state.allCharges.map(e => {
                                                                                    return <option key={e} value={e}>{e} {parseInt(e) === 1 ? "Mês" : "Meses"} </option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-lg-12 mt-3">
                                                                        <div className="alert alert-success">
                                                                            <span className="badge text-bg-success">Benefícios</span> <br />
                                                                            <h6 className="mt-2">
                                                                                Tenha acesso ao estabelecimento das <strong>{this.state.plan?.start}</strong> às <strong>{this.state.plan?.end}</strong> {this.state.plan.weekDays.filter(e => e)?.join(", ")}, por apenas: <p />
                                                                            </h6>
                                                                            {
                                                                                this.state.slots > 1 &&
                                                                                <strong className='text-success'>{this.state.slots} vagas</strong>
                                                                            }
                                                                            <h1><strong className='text-success'>{toCurrency(this.state.slots * this.state.plan.price)}</strong></h1> /
                                                                            <strong>Por mês</strong> <br />
                                                                            {
                                                                                this.state.slots > 1 && <strong>{toCurrency(this.state.plan.price)} por vaga</strong>
                                                                            }
                                                                            <div className="col-md-12 col-lg-3 mt-2">
                                                                                {
                                                                                    this.state.flags?.contract?.isEnabled ?
                                                                                        <button className="btn btn-success w-100" onClick={this.showContract}>
                                                                                            <i className="fas fa-trophy mx-2" />
                                                                                            Continuar
                                                                                        </button>
                                                                                        :
                                                                                        <button className="btn btn-success w-100" onClick={this.saveMonthly}>
                                                                                            <i className="fas fa-trophy mx-2" />
                                                                                            {this.state.flags?.isPaymentEnabled ? "Seguir para o pagamento" : "Confirmar cadastro"}
                                                                                        </button>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-center">
                                                                        {
                                                                            this.state.flags?.isPaymentEnabled ?
                                                                                <small>
                                                                                    Seguindo para o pagamento você concorda com os termos de uso e política de privacidades da plataforma e do estabelecimento. <br />

                                                                                </small> :
                                                                                <small>
                                                                                    Seguindo para o cadastro você concorda com os termos de uso e política de privacidades da plataforma e do estabelecimento. <br />
                                                                                </small>
                                                                        }
                                                                        <a href={this.state.resale.sitePrivacidade} target="_blank" rel="noreferrer"><strong>Termos de uso da plataforma</strong></a> <br />
                                                                        {
                                                                            this.state.park.sitePrivacidade &&
                                                                            <>
                                                                                <a href={`${this.state.park.sitePrivacidade}/termos-de-uso-e-politica-de-privacidade`} target="_blank" rel="noreferrer"><strong>Termos de uso do estabelecimento</strong></a> <br />
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    }
                                                </>
                                        }
                                    </>
                                }
                            </div>
                        }
                        {
                            this.state.state === "contract" &&
                            <div className="card-body">
                                <div className="row mt-3">
                                    <div className="text-center">
                                        <h3>Formulário de cadastro</h3>
                                        <span>Antes de proseguir, é preciso que você leia e aceite os termos de uso e/ou política de privacidade do estabelecimento.</span>
                                    </div>
                                    <div className="col-lg-12 mt-3">
                                        <textarea className="form-control" rows="15" disabled>
                                            {this.state.flags?.contract?.html}
                                        </textarea>
                                    </div>
                                    <div className="col-lg-12 mt-3">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" id="aceitarContratoDeUso" checked={this.state.aceitarContratoDeUso} onChange={(e) => this.setState({ aceitarContratoDeUso: e.target.checked })} type="checkbox" />
                                            <label className="form-check-label" htmlFor="aceitarContratoDeUso">
                                                Li e concordo com os termos de uso.
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt-3">
                                        <div className="btn-group">
                                            <button className="btn btn-secondary" onClick={(e) => { this.setState({ state: "fill" }) }}>
                                                <i className="fas fa-chevron-left mx-2" />
                                                Voltar
                                            </button>
                                            <button className="btn btn-success" onClick={this.saveMonthly}>
                                                <i className="fas fa-trophy mx-2" />
                                                {this.state.flags?.isPaymentEnabled ? "Seguir para o pagamento" : "Confirmar cadastro"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                    </RegisterContainer>
                </div>
            </>
        )
    }
}

class RegisterLoginView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            state: "loading",
            plate: "",
            phone: "",
            parkId: "",
            park: null,
            parks: []
        }
    }

    componentDidMount = async () => {
        const client = await loadClientSession()
        if (client) {
            this.setState({ data: client })
            this.setState({ state: "loading" })
            const data = await initializeForm({ id: client.id })
            if (data.success) {
                this.setState({ ...data.data }, () => {
                    this.changePark(queryString("parkId"))
                })
                this.setState({ state: "fill" })
            } else {
                this.setState({ errorMessage: data.message })
                this.setState({ state: "not-found" })
            }
        } else {
            this.setState({ state: "not-found" })
        }
    }

    changePark = async (value) => {
        if (value) {
            this.setState({ parkId: value })
            const park = this.state.parks.filter(e => e.id === value)[0]
            this.setState({ park: park })
            this.setState({ plans: park.plans })
            this.setState({ flags: park.flags })
        }
    }

    doMonthlyLogin = async () => {
        if (!this.validateFields()) {
            return
        }
        const body = {
            parkId: this.state.parkId,
            plate: this.state.plate,
            phone: this.state.phone
        }
        this.setState({ state: "loading" })
        const result = await loginForm({ data: body })
        if (result.success) {
            const id = result.data.id
            if (result.data.type === "monthly") {
                goToWindow(`/mensalista/cobrancas/?id=${queryString("id")}&m=${id}`)
            } else {
                goToWindow(`/credenciado?id=${queryString("id")}&c=${id}`)
            }
        } else {
            toastWarning(result.message)
            this.setState({ state: "fill" })
        }
    }

    validateFields = () => {
        if (this.state.parkId === "") {
            toastWarning("Selecione o estabelecimento.")
            return false
        }
        if (isNullOrEmpty(this.state.phone)) {
            toastWarning("Informe um celular válido.")
            return false
        }
        if (!isValidatePlate(this.state.plate)) {
            toastWarning("Informe uma placa válida.")
            return false
        }
        return true
    }

    render() {
        return (
            <MainContainer>
                <div className="container-md top-20">
                    {
                        this.state.data?.tema?.logo &&
                        <ImageContainer url={this.state.data?.tema?.logo} />
                    }
                    <RegisterContainer>
                        {this.state.state === "not-found" &&
                            <div className="alert alert-danger text-center mt-4">
                                <span className="badge text-bg-danger">Oops!</span> <p />
                                <h4>Cobrança não encontrada</h4>
                                <span>Entre em contato com o estabelecimento.</span>
                            </div>
                        }
                        {this.state.state === "loading" &&
                            <div className="text-center">
                                <LoadingContainer />
                            </div>
                        }
                        {
                            this.state.state === "fill" &&
                            <div className="row mt-3">
                                <div className="text-center">
                                    <h3>Login</h3>
                                    <span>Acesse todas as suas informações após o login.</span>
                                </div>
                                <div className="col-lg-12 mt-3">
                                    <div className="row">
                                        <div className="col-md-12 col-lg-4">
                                            <strong>Estabelecimento</strong>
                                            <select className='form-select' value={this.state.parkId} onChange={(e) => this.changePark(e.target.value)}>
                                                <option value="">Selecionar</option>
                                                {
                                                    this.state.parks.map(e => {
                                                        return <option key={e.id} value={e.id}>{e.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-12 col-lg-4">
                                            <label>Celular</label>
                                            <NumberFormat className="form-control" value={this.state.phone} mask='_' onChange={(e) => this.setState({ phone: e.target.value })} format="(##)#####-####" />
                                        </div>
                                        <div className="col-md-12 col-lg-4">
                                            <strong>Placa de um veículo</strong>
                                            <input type='text' className='form-control' value={this.state.plate} onChange={(e) => this.setState({ plate: e.target.value.toUpperCase() })} />
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-12 mt-3">
                                        <button onClick={(e) => { this.doMonthlyLogin() }} className="btn btn-success w-100">
                                            <i className="fas fa-lock mx-2" />
                                            Fazer login
                                        </button>
                                    </div>
                                    <div className="text-center mt-1">
                                        <h5>OU</h5>
                                        <div className="col-md-12 col-lg-12">
                                            <button onClick={(e) => { goToWindow(`/formulario/cadastrar/?id=${queryString("id")}`) }} className="btn btn-success w-100">
                                                <i className="fas fa-pencil mx-2" />
                                                Cadastrar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </RegisterContainer>
                </div>
            </MainContainer>
        )
    }
}

export { RegisterView, RegisterLoginView }