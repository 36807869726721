
import React from 'react'
import { queryString } from '../../shared/utils'
import { LoadingContainer, MainContainer } from '../../shared/layout/system-container'
import { ImageContainer, RegisterContainer } from '../../shared/layout/ticket-container'
import { ParkCardView } from '../cards'
import { getURLFromURI } from '../../config/api/filesWorker'
import { getAccessCardById } from '../../config/api/form/formAccessCard'
import { QRCodeSVG } from 'qrcode.react';

class AccessCardView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            state: "loading",
            id: queryString("id"),
            plate: null,
            name: "",
            cellphone: "",
            document: "",
            email: "",
            park: null
        }
    }

    componentDidMount = async () => {
        const result = await getAccessCardById({ id: this.state.id })
        this.setState({ state: "loading" })
        if (result.success) {
            this.setState({ ...result.data })
            await this.applyTheme({ park: result.data.park })
            this.setState({ state: "fill" })
        } else {
            this.setState({ errorMessage: result.message })
            this.setState({ state: "not-found" })
        }
    }

    applyTheme = async ({ park }) => {
        const url = await getURLFromURI(park.settings.logo)
        this.setState({ url: url })
        document.title = park.name
        document.body.style.backgroundColor = park.settings.backgroundColor
        const metaThemeColor = document.querySelector("meta[name=theme-color]");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", park.settings.backgroundColor);
        }
    }

    render() {
        return (
            <div style={{ width: "390px", margin: "0 auto" }}>
                <MainContainer>
                    {
                        this.state.url &&
                        <ImageContainer url={this.state.url} />
                    }
                    <div className="container-md top-20">
                        <RegisterContainer>
                            {
                                this.state.state === "not-found" &&
                                <div className="alert alert-danger text-center mt-4">
                                    <span className="badge text-bg-danger">Oops!</span> <p />
                                    <h4>{this.state.errorMessage}</h4>
                                    <span>Entre em contato com o estabelecimento.</span>
                                </div>
                            }
                            {
                                this.state.state === "loading" &&
                                <div className="text-center">
                                    <LoadingContainer />
                                </div>
                            }
                            {
                                this.state.state === "fill" &&
                                <div className="row">
                                    <ParkCardView park={this.state.park} />
                                    {
                                        this.state.user &&
                                        <>
                                            <div className='text-bold text-center text-size-20'>CARTÃO DE ACESSO {this.state.user.cardNumber}</div>
                                            <div className="col-12 mt-3 text-center">
                                                <QRCodeSVG value={this.state.user.cardNumber} size={240} />
                                                <div className='text-center text-size-10 mt-2'>Aponte o QRCode na cancela de entrada ou saída.</div>
                                            </div>
                                            <div className="col-lg-12">
                                                <span className="badge text-bg-secondary">Cliente</span> <br />
                                                <h4>{this.state.user.name}</h4>
                                                celular: {this.state.user.cellphone} <br />
                                                vagas: {this.state.user.slots} <br />
                                            </div>
                                            {
                                                this.state.vehicle &&
                                                <div className="col-lg-12 mt-3">
                                                    <span className="badge text-bg-secondary">Veículo</span> <br />
                                                    <h4>{this.state.vehicle.plate}</h4>
                                                    {this.state.vehicle.model} {this.state.vehicle.color} <br />
                                                </div>
                                            }
                                            <div className="col-lg-12 mt-3">
                                                <div className="alert alert-danger">
                                                    <span className="badge text-bg-danger">Atenção</span> <br />
                                                    Este cartão é intransferível e só deve ser utilizado por uma pessoa.
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            }
                        </RegisterContainer>
                    </div>
                </MainContainer>
            </div>
        )
    }
}

export { AccessCardView }