
import React from 'react'
import { applyThemeBySettings, goToWindow, queryString } from '../../shared/utils'
import { LoadingContainer, MainContainer } from '../../shared/layout/system-container'
import { ImageContainer, RegisterContainer } from '../../shared/layout/ticket-container'
import { getEventDetail } from '../../config/api/form/formEvent'
import NumberFormat from 'react-number-format'

class EventDetailView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            state: "loading",
            id: queryString("id"),
            plate: null,
            name: "",
            cellphone: "",
            document: "",
            email: "",
            park: null
        }
    }

    componentDidMount = async () => {
        const result = await getEventDetail({ clientId: this.state.id })
        this.setState({ state: "loading" })
        if (result.success) {
            this.setState({ ...result.data })
            await applyThemeBySettings({ settings: result.data.settings })
            this.setState({ state: "fill" })
        } else {
            this.setState({ errorMessage: result.message })
            this.setState({ state: "not-found" })
        }
    }

    goToPayment = (item) => {
        goToWindow(`/formulario/evento/pagamento/?id=${item.id}`)
    }

    render() {
        return (
            <div style={{ width: "390px", margin: "0 auto" }}>
                <MainContainer>
                    {
                        this.state.url &&
                        <ImageContainer url={this.state.url} />
                    }
                    <div className="container-md top-20">
                        <RegisterContainer>
                            {
                                this.state.state === "not-found" &&
                                <div className="alert alert-danger text-center mt-4">
                                    <span className="badge text-bg-danger">Oops!</span> <p />
                                    <h4>{this.state.errorMessage}</h4>
                                    <span>Entre em contato com o estabelecimento.</span>
                                </div>
                            }
                            {
                                this.state.state === "loading" &&
                                <div className="text-center">
                                    <LoadingContainer />
                                </div>
                            }
                            {
                                this.state.state === "fill" &&
                                <>
                                    <div className="col-12">
                                        <strong>Nome do comprador ( opcional )</strong>
                                        <input type="text" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} className="form-control" />
                                    </div>
                                    <div className="col-12">
                                        <strong>CPF ou CNPJ</strong>
                                        <input type="text" onChange={(e) => this.setState({ document: e.target.value })} value={this.state.document} className="form-control" />
                                    </div>
                                    <div className="col-md-12 col-lg-4">
                                        <label>Celular</label>
                                        <NumberFormat className="form-control" value={this.state.cellphone} mask='_' onChange={(e) => this.setState({ cellphone: e.target.value })} format="(##)#####-####" />
                                    </div>
                                    <div className="col-12">
                                        <strong>E-mail ( opcional )</strong>
                                        <input type="email" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} className="form-control" />
                                    </div>
                                    <div className="col-lg-3">
                                        <strong>CEP</strong>
                                        <NumberFormat autoComplete='off' className="form-control" value={this.state.zipcode} onChange={(e) => this.fetchCEP(e.target.value)} format="#####-###" />
                                    </div>
                                    <div className="col-lg-3">
                                        <strong>Logradouro</strong>
                                        <input type="text" onChange={(e) => this.setState({ street: e.target.value })} value={this.state.street} className="form-control" required />
                                    </div>
                                    <div className="col-lg-3">
                                        <strong>Número do endereço</strong>
                                        <input type="text" onChange={(e) => this.setState({ number: e.target.value })} value={this.state.number} className="form-control" required />
                                    </div>
                                    <div className="col-lg-3">
                                        <strong>Complemento</strong>
                                        <input type="text" onChange={(e) => this.setState({ complement: e.target.value })} value={this.state.complement} className="form-control" />
                                    </div>
                                    <div className="row mt-3">
                                        <h4 className="text-center">Dados dos veículos</h4>
                                        <div className="col-md-4 col-lg-4">
                                            <strong>Placa do veículo</strong>
                                            <input type='text' className='form-control' value={this.state.plate} onChange={(e) => this.setState({ plate: e.target.value.toUpperCase() })} />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <strong>Modelo</strong>
                                            <input className="form-control" list="datalistModelsOptions" placeholder="Toque para buscar..." value={this.state.model} onChange={(e) => this.setState({ model: e.target.value })} />
                                            <datalist id="datalistModelsOptions">
                                                {
                                                    this.state.vehiclesModels.map(e => {
                                                        return <option key={e.value} value={e.value} />
                                                    })
                                                }
                                            </datalist>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <strong>Cor</strong>
                                            <select className='form-select' value={this.state.color} onChange={(e) => this.setState({ color: e.target.value })}  >
                                                <option value="">Selecionar</option>
                                                {
                                                    this.state.vehiclesColors.map(e => {
                                                        return <option key={e.value} value={e.value}>{e.value}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </>
                            }
                        </RegisterContainer>
                    </div>
                </MainContainer>
            </div>
        )
    }
}

export { EventDetailView }