
import React from 'react'
import { applyThemeBySettings, goToWindow, isNullOrEmpty, queryString } from '../../shared/utils'
import { LoadingContainer, MainContainer } from '../../shared/layout/system-container'
import { ParkCardView } from '../cards'
import { getEventClientDetail } from '../../config/api/form/formEvent'
import { ImageContainer, RegisterContainer } from '../../shared/layout/ticket-container'

class EventPaymentView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            state: "loading",
            id: queryString("id"),
            plate: null,
            name: "",
            cellphone: "",
            document: "",
            email: "",
            park: null
        }
    }

    componentDidMount = async () => {
        const result = await getEventClientDetail({ clientId: this.state.id })
        this.setState({ state: "loading" })
        if (result.success) {
            this.setState({ ...result.data })
            await applyThemeBySettings({ settings: result.data.settings })
            this.setState({ state: "fill" })
        } else {
            this.setState({ errorMessage: result.message })
            this.setState({ state: "not-found" })
        }
    }

    goToEvent = (item) => {
        goToWindow(`/formulario/evento/detalhes/?id=${item.id}`)
    }

    render() {
        return (
            <div style={{ width: "390px", margin: "0 auto" }}>
                <MainContainer>
                    {
                        this.state.url &&
                        <ImageContainer url={this.state.url} />
                    }
                    <div className="container-md top-20">
                        <RegisterContainer>
                            {
                                this.state.state === "not-found" &&
                                <div className="alert alert-danger text-center mt-4">
                                    <span className="badge text-bg-danger">Oops!</span> <p />
                                    <h4>{this.state.errorMessage}</h4>
                                    <span>Entre em contato com o estabelecimento.</span>
                                </div>
                            }
                            {
                                this.state.state === "loading" &&
                                <div className="text-center">
                                    <LoadingContainer />
                                </div>
                            }
                            {
                                this.state.state === "fill" &&
                                <div className="row mt-2">
                                    <div className='text-bold text-center text-size-20'>EVENTOS DÍSPONIVEIS</div>
                                    <ParkCardView park={this.state.park} />
                                    {
                                        this.state.client &&
                                        <ParkCardView park={this.state.client} />
                                    }
                                    {
                                        !isNullOrEmpty(this.state.events) ?
                                            <>
                                                {
                                                    this.state.events.map(item =>
                                                        <div className="col-lg-12 mt-2" onClick={(e) => this.goToEvent(item)}>
                                                            <div className="alert alert-secondary">
                                                                <span className="badge text-bg-secondary">{item.name}</span> <br />
                                                                <div className=''>{item.startDate} - {item.endDate}</div>
                                                                <div className=''>{item.location}</div>
                                                                <div className=''>{item.description}</div>
                                                                <div className=''>{item.contact}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </> :
                                            <div className="col-lg-12 text-center">
                                                <h4>Nenhum evento disponível no momento</h4>
                                            </div>
                                    }
                                </div>
                            }
                        </RegisterContainer>
                    </div>
                </MainContainer>
            </div>
        )
    }
}

export { EventPaymentView }